/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FadCommentService,
  FadProviderDetailRequest,
  FadProviderDetailResponse,
} from '@dmx-fad-ui/fad/data-access';
import { Subscription } from 'rxjs';
import { SimpleUtilsService } from '@dmx-fad-ui/fad/services';
import { CommentsAndRatings } from './commentsAndRatings';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ProviderResultComponent } from '../../provider-result/provider-result.component';
import { CommentChip } from './commentChip';
import { Comment } from './comment';
@Component({
  selector: 'atlas-provider-ratings-comments',
  templateUrl: './provider-ratings-comments.component.html',
  styleUrls: ['./provider-ratings-comments.component.scss'],
})
export class ProviderRatingsCommentsComponent implements OnInit, OnDestroy {
  @Input() providerDetails: FadProviderDetailResponse;
  commentsAndRatings: CommentsAndRatings;
  providerResult: ProviderResultComponent;
  commentsAndRatingsSub: Subscription;
  viewMoreComments: boolean = false;
  @Input() providerName: string;
  isNewproviderProfileExperience = environment.isTabProviderPageEnabled;
  commentChips: CommentChip[];
  commentFilter: string = '';
  showComments: Comment[];
  showReviews: boolean = true;
  totalRatingValue: string = '';
  ratingsCount: string;

  constructor(
    private fadCommentServ: FadCommentService,
    private utilsServ: SimpleUtilsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscribeToCommentService();
    const provDetReq: FadProviderDetailRequest = this.utilsServ.createFadProviderDetailRequest(
      this.providerDetails.payload.providerDetail.profileName,
    );
    this.ratingsCount = this.providerDetails.payload.providerDetail.reviewRatingCount;
    this.totalRatingValue = this.removeTrailingZero(
      this.providerDetails.payload.providerDetail.reviewRatingValue,
    );
    this.fadCommentServ.getProviderComments(provDetReq);
  }

  loadShowComments(): void {
    this.showComments = this.commentsAndRatings.comments.slice(0, this.showComments.length + 10);
    this.determineViewMore();
  }

  removeTrailingZero(value) {
    if (value === null || value === undefined || value.trim() === '') {
      return '';
    } else {
      return parseFloat(value).toString();
    }
  }

  parseCommentsFilter() {
    const chipCountMin = 3;
    this.commentChips = this.commentsAndRatings.commentChips.filter(
      (chip) => chip.chipCount >= chipCountMin,
    );
  }

  selectCommentFilter(filterName: string) {
    if (this.commentFilter === filterName) {
      this.commentFilter = '';
      this.showComments = this.commentsAndRatings.comments.slice(0, 10);
      this.determineViewMore();
    } else {
      this.commentFilter = filterName;
      this.showComments = this.commentsAndRatings.comments;
      this.determineViewMore();
    }
  }

  redirectToCommentsPage(): void {
    this.router.navigate([`/${this.providerName}/comments`]);
  }

  ngOnDestroy(): void {
    this.commentsAndRatingsSub.unsubscribe();
  }

  evaluateShouldShowReviews(commentsAndRatings: CommentsAndRatings): boolean {
    if (commentsAndRatings.comments.length === 0 && commentsAndRatings.ratings.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  determineViewMore(): void {
    if (
      this.commentsAndRatings.comments.length > this.showComments.length &&
      this.commentFilter === ''
    ) {
      this.viewMoreComments = true;
    } else {
      this.viewMoreComments = false;
    }
  }

  subscribeToCommentService() {
    this.commentsAndRatingsSub = this.fadCommentServ.providerCommentsSubj.subscribe((cNr) => {
      this.commentsAndRatings = cNr.payload.commentDetail.commentsAndRatings;
      this.showComments = this.commentsAndRatings.comments.slice(0, 10);
      this.determineViewMore();
      if (environment.commentFilterOn) {
        this.parseCommentsFilter();
      }

      this.showReviews = this.evaluateShouldShowReviews(this.commentsAndRatings);
    });
  }
}
